import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import "../styles/blocks/nine-box.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const NineBox = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query NineBox {
          datoCmsAboutPage {
            box1Title
            box2Title
            box3Title
            box4Title
            box5Title
            box6Title
            box7Title
            box8Title
            box9Title
          }
        }
      `}
      render={(data) => {
        const { datoCmsAboutPage } = data;
        console.log("data", data);
        return (
          <div className="nine-box">
            <h1 className="header-title">We Specialise In</h1>

            <Link to="/contact" className="nine-box-inner-wrap">
              {Object.values(datoCmsAboutPage).map((box, i) => {
                console.log("box", box);
                return (
                  <div className="nine-box-container">
                    <h1>{box}</h1>
                    <div className="icon">
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </div>
                );
              })}
              {/* <div className="nine-box-container">
                <h1>{data.datoCmsAboutPage.box1Title}</h1>
                <div className="icon">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
              <div className="nine-box-container">
                <h1>{data.datoCmsAboutPage.box2Title}</h1>
                <div className="icon">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
              <div className="nine-box-container">
                <h1>{data.datoCmsAboutPage.box3Title}</h1>
                <div className="icon">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
              <div className="nine-box-container">
                <h1>{data.datoCmsAboutPage.box4Title}</h1>
                <div className="icon">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
              <div className="nine-box-container">
                <h1>{data.datoCmsAboutPage.box5Title}</h1>
                <div className="icon">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
              <div className="nine-box-container">
                <h1>{data.datoCmsAboutPage.box6Title}</h1>
                <div className="icon">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
              <div className="nine-box-container">
                <h1>{data.datoCmsAboutPage.box7Title}</h1>
                <div className="icon">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
              <div className="nine-box-container">
                <h1>{data.datoCmsAboutPage.box8Title}</h1>
                <div className="icon">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
              <div className="nine-box-container">
                <h1>{data.datoCmsAboutPage.box9Title}</h1>
                <div className="icon">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div> */}
            </Link>
          </div>
        );
      }}
    />
  );
};
export default NineBox;
