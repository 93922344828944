import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Img from "gatsby-image";
import Layout from "../components/layout";
import "../styles/about-page.sass";
import Footer from "../components/footer";
import "../styles/title-img.sass";
import NineBox from "../components/9box";

const About = ({ data: { about } }) => (
  <Layout>
    <div className="title-container">
      <div className="title-image">
        <div className="title">
          <h1>{about.title}</h1>
        </div>
        <Img fluid={about.aboutPageTitleImage.fluid} />
      </div>
    </div>
    <article className="about">
      <HelmetDatoCms seo={about.seoMetaTags} />
      <div className="about__inner">
        {/* <h1 className="about__title">{about.title}</h1> */}
        <p className="about__lead">{about.subtitle}</p>
        <blockquote
          className="about__body"
          dangerouslySetInnerHTML={{
            __html: about.bioNode.childMarkdownRemark.html,
          }}
        />
        <div className="about__gallery">
          <Img fluid={about.photo.fluid} />
        </div>
      </div>
      <NineBox/>
    </article>
    <Footer />
  </Layout>
);

export default About;

export const query = graphql`
  query AboutQuery {
    about: datoCmsAboutPage {
      aboutPageTitleImage {
        fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
          src
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      photo {
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      bioNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
